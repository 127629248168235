<template>
  <div>
    <Modal
      ref="modal"
      classes="white"
      :size="this.isCompanyOnboardedWithoutBusinessAccount ? 'md' : 'lg'"
      :full="false"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
    >
      <template #header>
        <font-awesome-icon icon="money-bill" />
        Payments Profile
      </template>
      <template #body>
        <CounterpartyOnboarding
          @close="onClose"
          @success="onSuccess"
          @add-owner="onOwnerOnboardingSuccess"
          @owner-onboarding="onShowOwnerOnboarding"
          :parentLoading="loading"
          :performLightKyc="this.isCompanyOnboardedWithoutBusinessAccount"
          :owners="owners"
        />
      </template>
    </Modal>
    <Modal
      ref="ownerModal"
      classes="white"
      :full="false"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
      :size="'md'"
    >
      <template #header>
        <font-awesome-icon icon="user" fixed-width />
        Decision Makers
      </template>
      <template #body>
        <OwnerOnboarding
          @close="() => $refs.ownerModal.close()"
          @success="onOwnerOnboardingSuccess"
          :owner="owner"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import CounterpartyOnboarding from '../payments/counterparty/onboarding/CounterpartyOnboarding.vue'
import OwnerOnboarding from '../payments/counterparty/onboarding/OwnerOnboarding.vue'
import OwnerMixin from '../payments/counterparty/OwnerMixin'
import CounterpartyMixin from '../payments/counterparty/CounterpartyMixin'
import PayfacMixin from '../payments/PayfacMixin'
import eventBus from '../../eventBus'

export default {
  name: 'CompanyOnboarding',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    counterpartyData() {
      return this.$store.getters['payfac/getCounterpartyDataByCompanyId'](
        this.$store.state.session.company.company_id
      )
    }
  },
  methods: {
    onToggleCompanyOnboarding() {
      this.$refs.modal.open()
    }
  },
  async created() {
    this.loading = true
    if (this.isCompanyOnboardedWithoutBusinessAccount) {
      const { payload } = await this.fetchPayee()
      const { counterparty, owners: existingOwners } = payload
      if (counterparty && existingOwners?.length > 0) {
        this.owners = existingOwners
        await this.setCounterpartyData({ counterparty, owners: existingOwners })
      }
    }

    this.loading = false
  },
  mounted() {
    eventBus.$on('toggleCompanyOnboarding', this.onToggleCompanyOnboarding)
  },
  beforeUnmount() {
    eventBus.$off('toggleCompanyOnboarding', this.onToggleCompanyOnboarding)
  },
  components: {
    CounterpartyOnboarding,
    OwnerOnboarding
  },
  mixins: [OwnerMixin, CounterpartyMixin, PayfacMixin]
}
</script>
